import React from 'react';

function Event(props) {
	return (
		<div className="event container">
			<div className="row">
				<div className="col-4 event-image">
					<img src={props.img} alt="Oasis family" />
				</div>
				<div className="col-8 event-content">
					<p>{props.title}</p>
					<p>{props.date}</p>
					<span>
						<a href={props.link}>See More »</a>
					</span>
				</div>
			</div>
		</div>
	);
}

export default Event;
