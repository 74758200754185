import React from 'react';

function FoodItem(props) {
	return (
		<div className="food-list food-item">
			<div className="food-item_title">
				{props.foodItemTitle} — {props.foodItemPrice}
				{props.glutenFree}
			</div>
			<div className="food-item_description">{props.foodItemDesription}</div>
		</div>
	);
}

export default FoodItem;
