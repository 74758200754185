import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

import Slider from './Slider';

function Home() {
	return (
		<div>
			<Header />
			<div id="VeganOaas"></div>
			<div className="homepage">
				<Slider />
			</div>
			<Footer />
		</div>
	);
}

export default Home;
