import DrinksPhoto from './../../../img/other/drinks.png';
import FoodPhoto from './../../../img/other/food.png';
import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

function Menu() {
	return (
		<div>
			<Header />
			<div id="VeganOaas"></div>
			<div className="menu-choice container">
				<div className="row">
					<div className="menu-choice__ind menu-choice__drinks col-6">
						<img src={DrinksPhoto} alt="Drinks" />
						<span className="DrinksText">Drinks</span>
					</div>
					<div className="menu-choice__ind menu-choice__food col-6">
						<img src={FoodPhoto} alt="Food" />
						<span className="FoodText">
							<a href="/food">Food</a>
						</span>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Menu;
