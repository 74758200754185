import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

import OasisFamily from './../../../img/other/oasis-family-2.png';
import Opening from './../../../img/other/opening.png';
import FrontDoor from './../../../img/other/oasis-frontdoor.png';

function OurStory() {
	return (
		<div>
			<Header />
			<div id="VeganOaas"></div>
			<div className="ourstory container">
				<div className="row">
					<div className="col-6">
						<p>
							Behind the Oasis is Toomas Laigu, who has a big drive to change
							the world for the better with his actions. He is in his work and
							hobbies fully present with complete love and passion, inspiring
							others to give their maximum. Toomas radiates goodness and
							positivity, and it never goes unnoticed by the people around him.
							Toomas has attracted a very inspiring team, where each team member
							is with at least as much energy as he and brings innovative
							solutions and ideas to life at every level of the company.
						</p>
						<p>
							According to Toomas, the idea of ​​Oasis was born ten years ago
							after high school graduation while tripping around in India and
							enjoying vegetarian food daily. After months of food orgasms,
							Toomas clearly envisioned himself already enjoying inspiring
							vegetarian tastes in his own restaurant. As always in life, things
							manifest in their own right way and almost seven years ago he
							started a Vegan Cafe Inspiratsioon in Tallinn Old Town. After
							years of intensive work and self-development with cafe
							Inspiratsioon, Toomas was invited by Rotermann’s leaders to open a
							vegan restaurant in their quarter. Sometimes the right invitation
							is exactly what is needed and after long negotiations to find the
							right space, the area with a large terrace opening onto Korstna
							Square and also towards Stalker gate remained the best possible
							choice. In addition to the restaurant, there was also an ideal
							area for a vegan shop. This is how this crazy and big project got
							started. With full speed of reconstructions and creating the
							concept, the vegan shop and the Sun Terrace of the restaurant was
							opened already within a month.
						</p>
						<p>
							OASIS restaurant is offering exceptional experiences at every
							level. We are creating a green, lively and refreshing oasis at the
							restaurant, where you can easily shift into vacation mode while
							enjoying delicious and healthy food prepared with lots of love and
							awareness. It’s not our goal to create a fully vegan world but an
							opportunity to share our passion towards pure tastes and wholesome
							vegan food. The concept of Oasis was inspired initially by the
							plants and nature that nurtures us. We wish to bring the same
							touch of nature also into the urban environment.
						</p>
						<p>
							Next to the restaurant, there is also a vegan shop where you can
							find plant-based products made by the OASIS family, as well as new
							and interesting vegan delicacies from Estonia and the rest of the
							world.
						</p>
						<p>
							Our desire is to serve all beautiful souls who value healthy and
							high-quality plant-based food. We are welcoming all new and
							long-term veg-friendly friends both with children and pets!
						</p>
					</div>
					<div className="col-6">
						<img src={OasisFamily} alt="Oasis Family" />
						<img src={Opening} alt="Oasis Family" />
						<img src={FrontDoor} alt="Oasis Family" />
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default OurStory;
