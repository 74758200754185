import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	NavLink,
} from 'react-router-dom';
import OurStory from './OurStory/OurStory';
import JoinOurTeam from './JoinOurTeam/JoinOurTeam';
import Events from './Events/Events';
import Menu from './Menu/Menu';
import Home from './HomePage/Home';
import PopUp from './PopUp';
import FoodList from './Menu/FoodList';

export default function RoutingUrl() {
	return (
		<Router>
			<div>
				<nav className="navigation">
					<ul>
						<li>
							<NavLink to="/our-story" activeClassName="active">
								Our Story
							</NavLink>
						</li>
						<li>
							<NavLink to="/join-our-team" activeClassName="active">
								Join our team
							</NavLink>
						</li>
						<li>
							<NavLink to="/events" activeClassName="active">
								Events
							</NavLink>
						</li>
						<li>
							<NavLink to="/menu" activeClassName="active">
								Menu
							</NavLink>
						</li>
						<li>
							<NavLink to="/" exact activeClassName="active">
								Home
							</NavLink>
						</li>
					</ul>
				</nav>

				{/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
				<PopUp />
				<Switch>
					<Route path="/our-story">
						<OurStory />
					</Route>
					<Route path="/join-our-team">
						<JoinOurTeam />
					</Route>
					<Route path="/events">
						<Events />
					</Route>
					<Route path="/menu">
						<FoodList />
					</Route>
					<Route path="/">
						<Home />
					</Route>
					<Route path="/food">
						<Menu />
					</Route>
					<Route path="/menu/drinks">
						<Home />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}
