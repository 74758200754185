import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Event from './Event';

import OasisFamily from './../../../img/other/oasis-family-3.png';
import Opening from './../../../img/other/opening.png';
import LiveRandaja from './../../../img/other/event_randaja.png';

function Events() {
	return (
		<div>
			<Header />
			<div id="VeganOaas"></div>
			<div className="events">
				<Event
					img={LiveRandaja}
					title="RÄNDAJA concert"
					date="19.07.2021"
					link="#"
				/>
				<Event
					img={OasisFamily}
					title="Grand opening"
					date="11.06.2021"
					link="#"
				/>
				<Event
					img={Opening}
					title="Shop and Restaurant Sun Terrace Opening Party"
					date="06.06.2021"
					link="#"
				/>
			</div>
			<Footer />
		</div>
	);
}

export default Events;
