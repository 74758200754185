import React from 'react';
import RoutingUrl from './components/PageContent/RoutingUrl';

function App() {
	return (
		<div className="App">
			<RoutingUrl />
		</div>
	);
}

export default App;
