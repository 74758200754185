import React, { useState } from 'react';

function HeaderMobile(props) {
	const [isActive, setActive] = useState(false);

	const toggleClass = () => {
		setActive(!isActive);
	};

	return (
		<div
			className={isActive ? 'mobileheader' : 'mobilesechead'}
			onClick={toggleClass}
		>
			<div className="menubar-item">
				<div className="menubar-open"></div>
			</div>
		</div>
	);
}

export default HeaderMobile;
