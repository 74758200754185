import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import FoodSection from './FoodSection';
import FoodItem from './FoodItem';
import FoodImage from './../../../img/menu/explosion.png';
import FoodImage2 from './../../../img/menu/pumpkinsoup.png';
import FoodImage3 from './../../../img/menu/pavlova.png';
import FoodImage4 from './../../../img/menu/hotmama.png';
import FoodImage5 from './../../../img/menu/barbites.png';
import { FoodMenuData } from './FoodMenuData';
import GlutenFree from './../../../img/icons/gluten-free.svg';

const GlutenFreeIcon = (
	<img src={GlutenFree} alt="gluten-free" title="Gluten Free" />
);

function FoodList() {
	return (
		<div>
			<Header />
			<div id="VeganOaas"></div>
			<div id="food-menu">
				<FoodSection foodListTitle="Starters" />
				<div className="food-section">
					<FoodItem
						foodItemTitle={FoodMenuData[0].foodItemTitle}
						foodItemPrice={FoodMenuData[0].foodItemPrice}
						foodItemDesription={FoodMenuData[0].foodItemDesription}
					/>
					<FoodItem
						className="food-item-special"
						foodItemTitle={FoodMenuData[1].foodItemTitle}
						foodItemPrice={FoodMenuData[1].foodItemPrice}
						foodItemDesription={FoodMenuData[1].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[2].foodItemTitle}
						foodItemPrice={FoodMenuData[2].foodItemPrice}
						foodItemDesription={FoodMenuData[2].foodItemDesription}
					/>
				</div>
				<div className="food-list-image">
					<img src={FoodImage} alt="Food" />
				</div>
				<FoodSection foodListTitle="Soup and Salads" />
				<div className="food-section">
					<FoodItem
						foodItemTitle={FoodMenuData[3].foodItemTitle}
						foodItemPrice={FoodMenuData[3].foodItemPrice}
						foodItemDesription={FoodMenuData[3].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[4].foodItemTitle}
						foodItemPrice={FoodMenuData[4].foodItemPrice}
						foodItemDesription={FoodMenuData[4].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[5].foodItemTitle}
						foodItemPrice={FoodMenuData[5].foodItemPrice}
						foodItemDesription={FoodMenuData[5].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[6].foodItemTitle}
						foodItemPrice={FoodMenuData[6].foodItemPrice}
						foodItemDesription={FoodMenuData[6].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[7].foodItemTitle}
						foodItemPrice={FoodMenuData[7].foodItemPrice}
						foodItemDesription={FoodMenuData[7].foodItemDesription}
					/>
				</div>
				<div className="food-list-image food-list-image-2">
					<img src={FoodImage2} alt="Food" />
				</div>
				<FoodSection foodListTitle="Mains" />
				<div className="food-section">
					<FoodItem
						foodItemTitle={FoodMenuData[8].foodItemTitle}
						foodItemPrice={FoodMenuData[8].foodItemPrice}
						foodItemDesription={FoodMenuData[8].foodItemDesription}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[9].foodItemTitle}
						foodItemPrice={FoodMenuData[9].foodItemPrice}
						foodItemDesription={FoodMenuData[9].foodItemDesription}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[10].foodItemTitle}
						foodItemPrice={FoodMenuData[10].foodItemPrice}
						foodItemDesription={FoodMenuData[10].foodItemDesription}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[11].foodItemTitle}
						foodItemPrice={FoodMenuData[11].foodItemPrice}
						foodItemDesription={FoodMenuData[11].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[12].foodItemTitle}
						foodItemPrice={FoodMenuData[12].foodItemPrice}
						foodItemDesription={FoodMenuData[12].foodItemDesription}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[13].foodItemTitle}
						foodItemPrice={FoodMenuData[13].foodItemPrice}
						foodItemDesription={FoodMenuData[13].foodItemDesription}
					/>
				</div>
				<div className="food-list-image food-list-image-2">
					<img src={FoodImage4} alt="Food" />
				</div>
				<FoodSection foodListTitle="Desserts" />
				<div className="food-section">
					<FoodItem
						foodItemTitle={FoodMenuData[14].foodItemTitle}
						foodItemPrice={FoodMenuData[14].foodItemPrice}
						foodItemDesription={FoodMenuData[14].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[15].foodItemTitle}
						foodItemPrice={FoodMenuData[15].foodItemPrice}
						foodItemDesription={FoodMenuData[15].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[16].foodItemTitle}
						foodItemPrice={FoodMenuData[16].foodItemPrice}
						foodItemDesription={FoodMenuData[16].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[17].foodItemTitle}
						foodItemPrice={FoodMenuData[17].foodItemPrice}
						foodItemDesription={FoodMenuData[17].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[18].foodItemTitle}
						foodItemPrice={FoodMenuData[18].foodItemPrice}
						foodItemDesription={FoodMenuData[18].foodItemDesription}
					/>
				</div>
				<div className="food-list-image food-list-image-2">
					<img src={FoodImage3} alt="Food" />
				</div>
				<FoodSection foodListTitle="Sharer Platter" />
				<div className="food-section">
					<FoodItem
						foodItemTitle={FoodMenuData[19].foodItemTitle}
						foodItemPrice={FoodMenuData[19].foodItemPrice}
						foodItemDesription={FoodMenuData[19].foodItemDesription}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[20].foodItemTitle}
						foodItemPrice={FoodMenuData[20].foodItemPrice}
						foodItemDesription={FoodMenuData[20].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[21].foodItemTitle}
						foodItemPrice={FoodMenuData[21].foodItemPrice}
						foodItemDesription={FoodMenuData[21].foodItemDesription}
					/>
					<FoodItem
						foodItemTitle={FoodMenuData[22].foodItemTitle}
						foodItemPrice={FoodMenuData[22].foodItemPrice}
						foodItemDesription={FoodMenuData[22].foodItemDesription}
						glutenFree={GlutenFreeIcon}
					/>
				</div>
				<div className="food-list-image food-list-image-2">
					<img src={FoodImage5} alt="Food" />
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default FoodList;
