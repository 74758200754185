import React from 'react';
import OasisLogo from './../../img/icons/oasis_logo.svg';
import InstagramIcon from './../../img/icons/inst-icon.svg';
import FacebookIcon from './../../img/icons/fb-icon.svg';

import HeaderMobile from './HeaderMobile';

function Header() {
	return (
		<div className="header container">
			<div className="mobile-header">
				<HeaderMobile />
			</div>
			<div className="row">
				<div className="col-3">
					<a href="http://localhost:3000/">
						<img src={OasisLogo} alt="Logo" />
					</a>
				</div>
				<div className="col-6"></div>
				<div className="col-2 fb-inst-links icon-right">
					<span className="shop">Shop</span>
					<div className="header-icons">
						<a
							href="https://www.instagram.com/veganpoodoasis/"
							target="_blank"
							without
							rel="noopener noreferrer"
						>
							<img
								src={InstagramIcon}
								alt="instagram icon"
								className="instagram-icon"
							/>
						</a>
						<a
							href="https://www.facebook.com/veganpoodoasis"
							target="_blank"
							without
							rel="noopener noreferrer"
						>
							<img
								src={FacebookIcon}
								alt="facebook icon"
								className="facebook-icon"
							/>
						</a>
					</div>
				</div>
				<div className="col-2 fb-inst-links icon-left">
					<span className="restaraunt-title">Restaurant</span>
					<div className="header-icons">
						<a
							href="https://www.instagram.com/oasisrestoran/"
							target="_blank"
							without
							rel="noopener noreferrer"
						>
							<img
								src={InstagramIcon}
								alt="instagram icon"
								className="instagram-icon"
							/>
						</a>
						<a
							href="https://www.facebook.com/oasisrestoran"
							target="_blank"
							without
							rel="noopener noreferrer"
						>
							<img
								src={FacebookIcon}
								alt="facebook icon"
								className="facebook-icon"
							/>
						</a>
					</div>
				</div>
				<div className="col-1 lang">EN</div>
			</div>
		</div>
	);
}

export default Header;
