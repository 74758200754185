import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

import OasisFamily from './../../../img/other/oasis-family-2.png';
import Helis from './../../../img/other/helis.png';
import Nastja from './../../../img/other/nastja.png';

function JoinOurTeam() {
	return (
		<div>
			<Header />
			<div id="VeganOaas"></div>
			<div className="joinourteam container">
				<div className="row">
					<div className="col-9">
						<p>
							Do you want to change the world? Come and contribute as a member
							of the Oasis family in our kitchen, service side or a shop.
						</p>
						<p>
							It’s not our goal to create fully vegan world but an opportunity
							to share our passion towards pure tastes and wholesome vegan food.
							At the same time give inspiration as well for experimenting at
							home.
						</p>
						<p>
							Oasis Restaurant offers its guests high-level vegan experiences
							from pure and high-quality ingredients. Our wish is to activate
							all the senses and help the guests enjoy the magic of presence. We
							delight and surprise with the service as well as
							well-thought-through paradisiacal atmosphere and stress-relieving
							plants and colors.
						</p>
						<p>
							Next to the restaurant, there is also a vegan shop where you can
							find plant-based products made by the OASIS family, as well as new
							and interesting vegan delicacies from Estonia and the rest of the
							world.
						</p>
						<p>
							The concept of Oasis was inspired initially by the plants and
							nature that nurtures us. We wish to bring the same touch of nature
							also into the urban environment.
						</p>
						<p>
							Our desire is to serve all beautiful souls who value healthy and
							high-quality plant-based food. We are welcoming all new and
							long-term vegetarian friends both with children and pets!
						</p>
						<p>
							Are you afraid of no previous work experience? It’s okay, our good
							chefs and waiters will be happy to help you settle in quickly and
							cope with new tasks. The opportunity to raise awareness of the
							possibilities of vegan food is there – grab it and see you already
							in Tallinn, in the Rotermann quarter!
						</p>
						<p>
							We are waiting for your CV by email: shop@veganoasis.ee or
							restaurant@veganoasis.ee and let us know why you want to work for
							Oasis!
						</p>
					</div>
					<div className="col-3">
						<img src={Helis} alt="Oasis Family" />
						<img src={Nastja} alt="Oasis Family" />
					</div>
					<img
						src={OasisFamily}
						alt="Oasis Family"
						className="oasis-family one-pic"
					/>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default JoinOurTeam;
