import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import SliderTerrassa from './../../../img/slider/slider_1.png';
import SliderEggplant from './../../../img/slider/slider_2.png';
import SliderDrink from './../../../img/slider/slider_3.png';
import SliderTitle from './../../../img/slider/slider_4.png';

const images = [SliderTerrassa, SliderEggplant, SliderDrink, SliderTitle];

function Slider() {
	return (
		<div className="slide-container">
			<Slide>
				<div className="each-slide">
					<div style={{ backgroundImage: `url(${images[0]})` }}></div>
					<span id="BottomToTop">
						Sign-up for Oasis newsletter to not miss our news, special
						<br />
						offers,events and behind the scenes stories
						<br />
						<button className="signup">Sign Up</button>
					</span>
				</div>
				<div className="each-slide">
					<div style={{ backgroundImage: `url(${images[1]})` }}></div>
				</div>
				<div className="each-slide">
					<div style={{ backgroundImage: `url(${images[2]})` }}></div>
				</div>
				<div className="each-slide">
					<div style={{ backgroundImage: `url(${images[3]})` }}></div>
				</div>
			</Slide>
		</div>
	);
}

export default Slider;
