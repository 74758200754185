import React from 'react';

function FoodSection(props) {
	return (
		<div className="food-list container">
			<h2>{props.foodListTitle}</h2>
			<hr />
		</div>
	);
}

export default FoodSection;
