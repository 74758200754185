import React from 'react';

function Footer() {
	return (
		<div className="footer container">
			<div className="row">
				<div className="col-6">
					<h3>Contact</h3>
					<p>Rotermanni 14, Tallinn 10111, Eesti</p>
					<p>Restaurant: +372 55900522</p>
					<p>shop@veganoasis.ee</p>
					<p>restaurant@veganoasis.ee</p>
				</div>
				<div className="col-6">
					<h4>Shop</h4>
					<p>Monday - Sunday</p>
					<p>10 - 22</p>
					<h4>Restaurant</h4>
					<p>Sunday - Wednesday</p>
					<p>11 - 22</p>
					<p>Thursday - Saturday</p>
					<p>11 - 23</p>
				</div>
			</div>
			<hr />
			<div className="rights-reserved">
				<span>© 2021 All rights reserved</span>
			</div>
		</div>
	);
}

export default Footer;
