export const FoodMenuData = [
	{
		foodItemTitle: 'Gyros',
		foodItemPrice: '6',
		foodItemDesription:
			'Our classic Oasis gyros with thinly sliced Vöner, crispy potatoes, pickled peppers and plant-based feta dill dressing.',
	},
	{
		foodItemTitle: 'Tomato Feta Cheese foodgasm',
		foodItemPrice: '7',
		foodItemDesription:
			'Marinated Datterini tomatoes in oregano-vinaigrette, plant-based feta cream and mint-basil pesto topped with a protein crunch of gourmet nuts and super seed like hemp. Served on our signature gluter-free seeded bread crisps. ',
	},
	{
		foodItemTitle: 'Spinach Pie',
		foodItemPrice: '7',
		foodItemDesription:
			'Freshly seared pie crusted in herbed tofu, spinach and oozing with cashew-hemp lemon cream cheese served with a side of celeric remoulade. ',
	},
	{
		foodItemTitle: 'Pumpkin Soup',
		foodItemPrice: '8',
		foodItemDesription:
			'Slow-baked pumpkin herbed with rosemary, thyme, creamed to silky puree with rich coconut. Topped with fresh Broccolini, wild mushrooms, pumpkin seeds and toasted coconut. ',
	},
	{
		foodItemTitle: 'Peri-Peri Salad',
		foodItemPrice: '9',
		foodItemDesription:
			'Pan-grilled tempeh that took its time to soak in a tangy barbeque smoked vinaigrette. Served on a bed of italian lamb, romaine, iceberg lettuce, spinach topped with quinoa, cherry tomatoes, dates, roasted whole almonds, pomegranate and hemp seeds. Slathered in basil and avocado dressing topped with smoked umami seeds.',
	},
	{
		foodItemTitle: 'Cleopatra Ceasar Salad',
		foodItemPrice: '9',
		foodItemDesription:
			'Bed of Romaine and Iceberg lettuce, kale, heirloom tomatoes, coconut bacon bits, smoked chickpeas and brined artichokes. Dressed in refreshing caper-tahini topped with cashew parmesan and fermented onions.',
	},
	{
		foodItemTitle: 'Eden Paradise Salad',
		foodItemPrice: '9',
		foodItemDesription:
			'Gourmet salad greens, soil-ripened watermelon, avocados, heirloom tomatoes, fresh strawberries, organic snow peas topped with plant-based butter-cream dressing. Garnished with dried cranberries and crispy rice noodles.',
	},
	{
		foodItemTitle: 'Explosion',
		foodItemPrice: '9',
		foodItemDesription:
			'Bed of kale, spinach, crunchy red cabbage, mixed - greens, glazed red beets, smoky sweet potatoes, pomegranate topped with protein crunches like walnuts, pumpkin seeds, sunflower seeds. Sweet potato crisps, avocado with herbed bread crumbs slathered with a generous drizzle of cashew hemp dressing.',
	},
	{
		foodItemTitle: 'Char-Grilled Aubergines',
		foodItemPrice: '11',
		foodItemDesription:
			'Smoky aubergines with lemon zest cream cheese, rose tomato and parsnip chips beautifully dusted with red beets drizzled with wild garlic oil.',
	},
	{
		foodItemTitle: 'Hot Mama Burger',
		foodItemPrice: '12',
		foodItemDesription:
			'Crushed chipotle mixed beans, Japanese corn, chickpeas with a dash of five spice and herbs, on a bed of iceberg lettuce served in our exotic Kerala sauce made from flavoured activated spice seeds served on brioche bun',
	},
	{
		foodItemTitle: 'Naked Burger',
		foodItemPrice: '12',
		foodItemDesription:
			'Feast of Mexican beans, chickpeas and corn pressed into burger patties then baked in the oven. Served on iceberg lettuce topped with our signature guacomle and served with sweet potato crisps paired with a side of shredded seasonal greens of fermented broccole, radish, capsicum and fresh salsa with jalapeno.',
	},
	{
		foodItemTitle: 'Avocado Pesto Pasta',
		foodItemPrice: '11',
		foodItemDesription:
			'Gluten-free Buckwheat pasta, sud dried tomatoes and topped with a generous portion of cashew parmesan.',
	},
	{
		foodItemTitle: 'Jackfruit Burrito',
		foodItemPrice: '14',
		foodItemDesription:
			'Hash brown, green jackfruit meat, arugula, baked beans, pumpkin crunch and caramelized plantains served with our signature ranch.',
	},
	{
		foodItemTitle: 'Epic Mushroom Burrito',
		foodItemPrice: '13',
		foodItemDesription:
			'Umami - full combination of meaty wild mushrooms - Oyster, Enoki, Portobello, Chanterelle. Charred and dry roasted in spices to bring out the earthy flavours of smoke. Wrapped in tomato rice and mixed beans. Topped with oil-free rich guacamole, crunchy red cabbage and roasted capsicum.',
	},
	{
		foodItemTitle: 'Classic Chocolate Truffles',
		foodItemPrice: '4.5',
		foodItemDesription: '',
	},
	{
		foodItemTitle: 'Granny Smith Apple Lasagna',
		foodItemPrice: '7',
		foodItemDesription:
			'Granny Smith lasagne-style apple cake with lemon granita and our signature crumbled oats.',
	},
	{
		foodItemTitle: 'Lime Yoghurt',
		foodItemPrice: '7',
		foodItemDesription:
			'Lime youghurt layered with caramelized popcorn and topped with fresh respberries, strawberries, blueberries and passion fruit.',
	},
	{
		foodItemTitle: 'Secret Garden Pavlova',
		foodItemPrice: '8',
		foodItemDesription:
			'A marriage of baked and frozen pavlova oozing with English vanilla custard, balanced with spanish saffrron and topped with refreshing raspberries, blueberries, strawberries, sea buckthorn and crunchy pistachio.',
	},
	{
		foodItemTitle: 'Waltz Of Love',
		foodItemPrice: '13',
		foodItemDesription: 'Baklava, ice cream',
	},
	{
		foodItemTitle: 'Bar Bites',
		foodItemPrice: '7.5',
		foodItemDesription:
			'Vöner sliders served with chipotle mayonnaise, fermented cucumber and onions.',
	},
	{
		foodItemTitle: 'Cheese Platter',
		foodItemPrice: '7/14',
		foodItemDesription:
			'A plate of non-dairy cheeses, truffle maple sauce, gluten-free bread crisps, tapenade and a bunch of gourmet nuts with selected marmalades.',
	},
	{
		foodItemTitle: "Chef's very Special Sharing Plate for 2",
		foodItemPrice: '12',
		foodItemDesription:
			'Daily selection of different snacks chosen by our chef.',
	},
	{
		foodItemTitle: 'Meat and Cheese Platter by OASIS Shop',
		foodItemPrice: '11/22',
		foodItemDesription:
			'Selection of plant-based meat products and cheeses from OASIS shop.',
	},
];
