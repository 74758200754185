import { Link } from 'react-scroll';
import UpArrow from './../../img/icons/up-chevron.png';

import React, { useEffect, useState } from 'react';

export default function PopUp() {
	const [isVisible, setIsVisible] = useState(false);

	// Show button when page is scorlled upto given distance
	const toggleVisibility = () => {
		if (window.pageYOffset > 300) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	// Set the top cordinate to 0
	// make scrolling smooth
	const popUp = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);
	}, []);

	return (
		<div className="scroll-to-top">
			{isVisible && (
				<div onClick={popUp}>
					<Link smooth={true} to="VeganOaas">
						<img src={UpArrow} alt="Up" width="15px" />
					</Link>
				</div>
			)}
		</div>
	);
}
